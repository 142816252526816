import axios from 'axios';

//const baseURL = 'http://localhost:5000';
const baseURL = 'https://r2api.innovatebusiness.net';
 const baseURLTiendaDavid = 'https://r2api-tiendadavid.innovatebusiness.net';
//const baseURL = 'https://r2api-dse.innovatebusiness.net';

export default {
    getInvoiceData(code, key) {
        
        let options = {};
        if(key == "3d9f375900655f04b5513c0ea61313fc43fa5760e2ad9ad0"){
            options = {
                method: 'GET',
                url: `${baseURLTiendaDavid}/api/r2erp/invoice/${code}`,
                headers: {
                    'content-type': 'application/json',
                    'requester_secret_key': `${key}` 
                },
            };
        }
        else{
             options = {
                method: 'GET',
                url: `${baseURL}/api/r2erp/invoice/${code}`,
                headers: {
                    'content-type': 'application/json',
                    'requester_secret_key': `${key}` 
                },
            };
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                    reject(error.message);
                })
        });
    },
    async sendInvoiceMail(data, key) {
        const options = {
            timeout: 120000,
            method: 'POST',
            url: `${baseURL}/api/r2erp/invoice/sendEmail`,
            headers: {
                'content-type': 'application/json',
                'requester_secret_key': `${key}`
            },
            data
        };
        const result = await axios(options);
        
        const { data: { success, message } } = result;
        if( !success ) {
            console.log(result);
            throw message;
        }
        return result;
        
    },
};